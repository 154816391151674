
import * as aliyunRuntime$2qXQmdZjTO from '/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/providers/aliyun-oss/index.ts'
import * as ipxRuntime$PmLexF0kJd from '/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/@nuxt+image@1.8.1_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "banner": {
      "modifiers": {
        "fit": "cover",
        "height": 450
      },
      "sizes": "xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
    },
    "avatar": {
      "modifiers": {
        "format": "webp",
        "width": 60,
        "height": 60
      }
    },
    "product_splide": {
      "modifiers": {
        "format": "webp",
        "width": 600,
        "height": 600,
        "fit": "contain"
      }
    },
    "aft_img": {
      "modifiers": {
        "format": "webp",
        "width": 1920,
        "height": 1080,
        "fit": "cover"
      }
    }
  },
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aliyun']: { provider: aliyunRuntime$2qXQmdZjTO, defaults: {"baseURL":"https://resource.auditoryworks.co/nearhub-v1"} },
  ['ipx']: { provider: ipxRuntime$PmLexF0kJd, defaults: {} }
}
        