import { createMapper } from "#image";

/**@see: https://github.com/nuxt/image/blob/main/src/runtime/providers/gumlet.ts
 * @see: https://help.aliyun.com/document_detail/44688.html
 */
// export const options = {
//     valueMap: {
//         fit: {
//             fill: "fixed",
//             inside: "lfit",
//             outside: "mfit",
//             cover: "fill",
//             contain: "lfit",
//         },
//     },
//     keyMap: {
//         width: "w",
//         height: "h",
//         fit: "m",
//         format: "format",
//         quality: "q",
//         backgroundColor: "bg",
//         rotate: "rotate",
//     },
//     actionMap: {
//         quality: "quality",
//         format: "format",
//         h: "resize",
//         w: "resize",
//     },
//     joinOpreactionWith: ",",
//     joinActionWith: "/",
//     formatter: (key: string, value: any) => `${key}_${value}`,
// };

/**
 * 1. generate all opreation , splicing with , format with _
 * 2. group opreation to action   //process generate action -> parame_value
 * 3. sort action
 * 4. join action , splicing with /
 */
export function createOperationsGenerator({ formatter, keyMap, actionMap, joinWith = "/", valueMap }: any = {}) {
    if (!formatter) {
        formatter = (key: string, value: string) => `${key}=${value}`;
    }
    if (keyMap && typeof keyMap !== "function") {
        keyMap = createMapper(keyMap);
    }
    const map = valueMap || {};
    Object.keys(map).forEach((valueKey) => {
        if (typeof map[valueKey] !== "function") {
            map[valueKey] = createMapper(map[valueKey]);
        }
    });

    function getActionName(name: string) {
        if (actionMap[name]) {
            return actionMap[name];
        } else {
            throw "请实现该方法";
        }
    }

    return (modifiers: any = {}) => {
        const actionStore: { [key: string]: any } = {};
        const actionWithOpreations = Object.entries(modifiers)
            .filter(([_, value]) => typeof value !== "undefined")
            .reduce((prev, [key, value]) => {
                const mapper = map[key];
                if (typeof mapper === "function") {
                    value = mapper(modifiers[key]);
                }
                key = typeof keyMap === "function" ? keyMap(key) : key;
                const actionName = getActionName(key);
                const currentAction = prev[actionName] || (prev[actionName] = []);
                currentAction.push(formatter(key, value));
                return prev;
            }, actionStore);

        return Object.entries(actionWithOpreations)
            .map(([key, value]) => `${key},${value.join(",")}`)
            .join(joinWith);
    };
}
