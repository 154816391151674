/*
 * @Author: lich
 * @Date: 2022-11-24 00:39:49
 * @Last Modified by: Luca
 * @Last Modified time: 2024-08-03 22:20:49
 * @see: https://help.aliyun.com/document_detail/183902.htm?spm=a2c4g.11186623.0.0.172f24cbSBkxiy#table-kz6-dby-5au
 */
import { joinURL } from "ufo";
import { createOperationsGenerator } from "./utils";

const operationsGenerator = createOperationsGenerator({
    valueMap: {
        fit: {
            fill: "fixed",
            inside: "lfit",
            outside: "mfit",
            cover: "fill",
            contain: "lfit",
        },
    },
    keyMap: {
        width: "w",
        height: "h",
        fit: "m",
        format: "format",
        quality: "q",
        backgroundColor: "bg",
        rotate: "rotate",
    },
    actionMap: {
        q: "quality",
        format: "format",
        h: "resize",
        w: "resize",
        rotate: "rotate",
        m: "resize",
    },
    joinOpreactionWith: ",",
    joinActionWith: "/",
    formatter: (() => {
        const exclude = ["format", "rotate"];
        return (key: string, value: any) => (exclude.includes(key) ? value : `${key}_${value}`);
    })(),
});

export function getImage(src: string, { modifiers, baseURL }: any = {}, { options, $img }: any) {
    modifiers.format = "webp";
    const operationsString = operationsGenerator(modifiers);
    return {
        url: joinURLBase(baseURL, src) + (operationsString ? `?x-oss-process=image/${operationsString}` : ""),
    };
}

function joinURLBase(baseURL: string, path: string) {
    if (path.startsWith("http") || path.startsWith("//") || path.startsWith("data:")) return path;
    if (baseURL === undefined) return path;
    if (baseURL.endsWith("/")) baseURL = baseURL.slice(0, -1);
    if (path.startsWith("/")) path = path.slice(1);
    return `${baseURL}/${path}`;
}
